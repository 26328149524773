import { updateYear, updateMonth } from "./slice";
import { useAppDispatch, useAppSelector } from "app/store";

export const useJeAccrualYearMonthFilters = () => {
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.jeAccrualYearMonthFilters);

  return {
    jeAccrualYearMonthFilters: selector,
    updateYear: (payload: number) => dispatch(updateYear(payload)),
    updateMonth: (payload: string) => dispatch(updateMonth(payload)),
  };
};
