import React from "react";
import { useTranslation } from "react-i18next";
import TopNavigation from "@amzn/awsui-components-react/polaris/top-navigation";
import { useAppSelector } from "app/store";
import { i18nStrings, profileActions } from "./config";
import styles from "./style.module.scss";
import logo from "./logo.svg";

const Header: React.FC = () => {
  const { fullName, email, alias } = useAppSelector((state) => state.auth.user);
  const { t } = useTranslation();

  return (
    <TopNavigation
      id="header"
      className={styles.header}
      i18nStrings={i18nStrings}
      identity={{
        href: "#",
        title: t("app_name"),
        logo: { src: logo, alt: t("app_name") },
      }}
      utilities={[
        {
          type: "menu-dropdown",
          text: fullName + " (" + alias + ")",
          description: email,
          iconName: "user-profile",
          items: profileActions,
        },
      ]}
    />
  );
};

export default Header;
