import React, { Suspense } from "react";
import Loader from "components/loader";
import { Routes, Route } from "react-router-dom";
import Home from "pages/home";
import Error404 from "pages/error-404";
/* PLOP_IMPORT_ROUTES */
import Approvals from "pages/approvals";
import Reporting from "pages/reporting";
import BusinessRules from "pages/business-rules";
import DataOrchestration from "pages/data-orchestration";
import Downloads from "pages/downloads";
import JeAccrual from "pages/je-accrual";
import CalculateTaxes from "pages/calculate-taxes";
import { useVariation } from "features/evidently";
import {
  JE_ACCRUAL_FEATURE,
  BUSINESS_RULE_FEATURE,
  DATA_ORCHESTRATION_FEATURE,
  UTA_VISUALIZATION_REPORTING,
  JE_ACCRUAL_APPROVAL_FEATURE,
} from "features/evidently/constants";

const Router: React.FC = (): JSX.Element => {
  const { isEnabled: isJEAccrualEnabled } = useVariation(JE_ACCRUAL_FEATURE);
  const { isEnabled: isDataOrchestrationEnabled } = useVariation(
    DATA_ORCHESTRATION_FEATURE
  );
  const { isEnabled: isBusinessRulesEnabled } = useVariation(
    BUSINESS_RULE_FEATURE
  );
  const { isEnabled: isUtaVREnabled } = useVariation(
    UTA_VISUALIZATION_REPORTING
  );
  const { isEnabled: isJEApprovalEnabled } = useVariation(
    JE_ACCRUAL_APPROVAL_FEATURE
  );

  return (
    <Suspense fallback={<Loader loadingText="Loading..." />}>
      <Routes>
        <Route path="/" element={<Home />} />

        {/* PLOP_INJECT_ROUTES */}
        {isJEApprovalEnabled && (
          <Route path="/approvals" element={<Approvals />} />
        )}
        {isUtaVREnabled && <Route path="/reporting" element={<Reporting />} />}
        {isBusinessRulesEnabled && (
          <Route path="/business-rules" element={<BusinessRules />} />
        )}
        {isDataOrchestrationEnabled && (
          <Route path="/data-orchestration" element={<DataOrchestration />} />
        )}
        <Route path="/downloads/:fileId" element={<Downloads />} />
        {isJEAccrualEnabled && (
          <Route path="/je-accrual" element={<JeAccrual />} />
        )}
        <Route path="/calculate-taxes" element={<CalculateTaxes />} />

        {/* Must be the last route */}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
