/* eslint css-modules/no-unused-class: [2, { markAsUsed: ['fullscreen-enabled'] }] */
import React from "react";
import { FullscreenHandle } from "../../hooks/useFullscreen";
import styles from "./Fullscreen.module.scss";

interface FullscreenProps {
  handle: FullscreenHandle;
  id?: string;
}

export const Fullscreen: React.FC<FullscreenProps> = ({ handle, id, children }): JSX.Element => {
  const className = handle.isFullscreen ? "fullscreen-enabled" : "";
  return (
    <div ref={handle.element} className={styles[className]} id={id}>
      {children}
    </div>
  );
};

export default Fullscreen;
