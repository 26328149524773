import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { EvidentlyContext } from "components/evidently-feature/evidentlyContext";
import { EvidentlyFeature } from "./interfaces";

export const loadVariations = createAsyncThunk(
  "evidently/loadVariations",
  async (evidentlySettings: any, { getState }) => {
    const alias = (getState() as RootState).auth.user.alias;
    await EvidentlyContext.loadVariations(evidentlySettings, alias);
    if (EvidentlyContext.isError) return Promise.reject();
    const features: Record<string, EvidentlyFeature> = {};
    evidentlySettings.features.forEach((featureName: string) => {
      features[featureName] = {
        variation: EvidentlyContext.getFeatureVariation(featureName),
        value: EvidentlyContext.getFeatureVariationValue(featureName),
      };
    });
    return { features };
  }
);
