import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/store";
import { settingsLoaded } from "./slice";
import { useGetSettingsQuery } from "./endpoints";

export const useSettings = () => {
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.settings);
  const { data } = useGetSettingsQuery();

  useEffect(() => {
    if (data) {
      dispatch(settingsLoaded(data));
    }
  }, [dispatch, data]);

  return {
    settings: selector,
  };
};
