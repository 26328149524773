import React from "react";
import Flashbar, {
  FlashbarProps,
} from "@amzn/awsui-components-react/polaris/flashbar";
import { useNotifications } from "./hooks";

const Notifications: React.FC = () => {
  const { dismissNotification, notifications } = useNotifications();
  const { items } = notifications;

  if (items.length === 0) {
    return <></>;
  }

  const barItems: FlashbarProps.MessageDefinition[] = [];
  for (let i = 0; i < items.length; i++) {
    const notification = items[i];
    barItems.push({
      onDismiss: () => dismissNotification(notification),
      ...notification,
    });

    if (notification.timeout) {
      setTimeout(() => {
        dismissNotification(notification);
      }, notification.timeout);
    }
  }

  return <Flashbar items={barItems} data-testid="notifications" />;
};

export default Notifications;
