import React from "react";

import Box from "@amzn/awsui-components-react/polaris/box";

import { IClickableTextProps } from "./interfaces";
import styles from "./style.module.scss";

const ClickableText: React.FC<IClickableTextProps> = (
  props: IClickableTextProps
) => {
  return (
    <Box
      {...props}
      className={styles.clickableText}
      data-testid="clickable-text"
    >
      <span>{props.text}</span>
    </Box>
  );
};

export default ClickableText;
