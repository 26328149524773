import React from "react";
import styles from "./style.module.scss";
import Select from "@amzn/awsui-components-react/polaris/select";
import {
  MONTHS_FILTER,
  YEARS_FILTER,
} from "features/calculate-taxes-table-filters/constants";
import PropertyFilter, {
  PropertyFilterProps,
} from "@amzn/awsui-components-react/polaris/property-filter";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import { useDataOrchestrationYearMonthFilters } from "./hooks";
import { useNotifications } from "features/notifications/hooks";
import { IDataOrchestrationTableFiltersProps } from "./interfaces";
import { getFilterCounterText } from "components/client-side-paginated-table/utils";
import { PROPERTY_FILTERING_I18N_CONSTANTS } from "components/table-state/constants";

const DataOrchestrationYearMonthFilters: React.FC<
  IDataOrchestrationTableFiltersProps
> = ({ filteringProperties, propertyFilterProps, filteredItemsCount }) => {
  const {
    dataOrchestrationYearMonthFilters: { year, month },
    updateYear,
    updateMonth,
  } = useDataOrchestrationYearMonthFilters();

  const { clearNotifications } = useNotifications();
  const PROPERTY_FILTER_PROPS: PropertyFilterProps = filteringProperties && {
    ...propertyFilterProps,
    i18nStrings: PROPERTY_FILTERING_I18N_CONSTANTS,
    countText: getFilterCounterText(filteredItemsCount),
  };
  return (
    <div
      className={styles.dataOrchestrationYearMonthFilters}
      data-testid="data-orchestration-year-month-filters"
    >
      <Grid gridDefinition={[{ colspan: 3 }, { colspan: 2 }, { colspan: 2 }]}>
        <div>
          <PropertyFilter {...PROPERTY_FILTER_PROPS} />
        </div>
        <div>
          <Select
            data-testid="data-orchestration-year-filters"
            selectedOption={{ label: year.toString(), value: year.toString() }}
            options={YEARS_FILTER}
            placeholder="Year"
            filteringType="auto"
            onChange={({ detail }) => {
              clearNotifications();
              updateYear(parseInt(detail.selectedOption.value!));
            }}
          />
        </div>
        <div>
          <Select
            data-testid="data-orchestration-month-filters"
            selectedOption={{
              label: month,
              value: month,
            }}
            options={MONTHS_FILTER}
            placeholder="Month"
            filteringType="auto"
            onChange={({ detail }) => {
              clearNotifications();
              updateMonth(detail.selectedOption.value!);
            }}
          />
        </div>
      </Grid>
    </div>
  );
};

export default DataOrchestrationYearMonthFilters;
