import { SortOrder } from "@amzn/polaris-data-grid";

export const sortRows = <T = any>(
  rows: readonly T[],
  sortProperty: string,
  sortOrder: SortOrder,
  sortTransform?: (item: any) => number | string
): T[] => {
  const sort = sortOrder === SortOrder.ASC ? 1 : -1;
  const sortedRows = [...rows].sort((a: any, b: any) => {
    const i = sortTransform ? sortTransform(a) : a[sortProperty];
    const j = sortTransform ? sortTransform(b) : b[sortProperty];
    return i > j ? sort : -sort;
  });
  return sortedRows;
};
