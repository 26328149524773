export const emptyValue = "-";

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export enum UseTaxFileType {
  CALCULATION_INPUT = "CALCULATION_INPUT",
  CALCULATION_OUTPUT = "CALCULATION_OUTPUT",
  SUMMARY = "SUMMARY",
}
