import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICalculateTaxesTableMultiSelectFiltersState } from "./interfaces";
import { getYearMonthForUseTaxCalculationFilter } from "../../utils";

const yearAndMonth = getYearMonthForUseTaxCalculationFilter();

const initialState: ICalculateTaxesTableMultiSelectFiltersState = {
  years: [yearAndMonth[0]],
  months: [yearAndMonth[1]],
  pageNumber: 1,
  pageSize: 20,
};

export const calculateTaxesTableMultiSelectFiltersSlice = createSlice({
  name: "calculateTaxesTableMultiSelectFilters",
  initialState,
  reducers: {
    yearsMonthsUpdate: (
      state,
      action: PayloadAction<ICalculateTaxesTableMultiSelectFiltersState>
    ) => {
      state.years = action.payload.years;
      state.months = action.payload.months;
      state.pageNumber = action.payload.pageNumber;
      state.pageSize = action.payload.pageSize;
    },
  },
});

export const { yearsMonthsUpdate } =
  calculateTaxesTableMultiSelectFiltersSlice.actions;

export default calculateTaxesTableMultiSelectFiltersSlice.reducer;
