import React from "react";
import { Column, SelectCellFormatter, SelectColumn, useRowSelection } from "react-data-grid";
import { IsItemDisabled, SelectionType } from "../../interfaces/Selection";

const DEFAULT_WIDTH = 55;

interface SelectionColumnProps<T> {
  selectionType?: SelectionType;
  cellClass?: string;
  headerCellClass?: string;
  isItemDisabled?: IsItemDisabled<T>;
  allItemsSelected?: boolean;
  selectAllDisabled?: boolean;
  onAllItemsSelectionChange?: (checked: boolean) => void;
}

interface MultiSelectFormatterProps<T> {
  isCellSelected: boolean;
  row: T;
  isItemDisabled?: IsItemDisabled<T>;
}

const MultiSelectFormatter = <T extends unknown>({
  isCellSelected,
  row,
  isItemDisabled,
}: MultiSelectFormatterProps<T>): JSX.Element => {
  const [isRowSelected, onRowSelectionChange] = useRowSelection();
  return (
    <SelectCellFormatter
      aria-label="Select"
      isCellSelected={isCellSelected}
      value={isRowSelected}
      onChange={(checked) => {
        onRowSelectionChange({ checked, isShiftClick: false, row });
      }}
      disabled={isItemDisabled && isItemDisabled(row)}
    />
  );
};

MultiSelectFormatter.defaultProps = {
  isItemDisabled: undefined,
};

export const MultiSelectColumn = <T extends unknown>({
  cellClass,
  headerCellClass,
  isItemDisabled,
  allItemsSelected = false,
  selectAllDisabled = false,
  onAllItemsSelectionChange = () => {},
}: SelectionColumnProps<T>): Column<T, unknown> => {
  return {
    ...SelectColumn,
    width: DEFAULT_WIDTH,
    maxWidth: DEFAULT_WIDTH,
    headerCellClass,
    cellClass,
    formatter: ({ isCellSelected, row }) => {
      return (
        <MultiSelectFormatter
          isCellSelected={isCellSelected}
          row={row}
          isItemDisabled={isItemDisabled}
        />
      );
    },
    headerRenderer: ({ isCellSelected }) => {
      return (
        <SelectCellFormatter
          aria-label="Select All"
          isCellSelected={isCellSelected}
          value={allItemsSelected}
          onChange={onAllItemsSelectionChange}
          disabled={selectAllDisabled}
        />
      );
    },
  };
};

export const SingleSelectColumn = <T extends unknown>({
  cellClass,
  headerCellClass,
  isItemDisabled,
}: SelectionColumnProps<T>): Column<T, unknown> => {
  return {
    ...MultiSelectColumn({
      cellClass,
      headerCellClass,
      isItemDisabled,
    }),
    headerRenderer: () => <span data-testid="single-select-header" />,
  };
};

export const SelectionColumn = <T extends unknown>({
  selectionType,
  cellClass,
  headerCellClass,
  isItemDisabled,
  allItemsSelected,
  selectAllDisabled,
  onAllItemsSelectionChange,
}: SelectionColumnProps<T>): Column<T, unknown> | null => {
  if (selectionType === "single") {
    return SingleSelectColumn({
      cellClass,
      headerCellClass,
      isItemDisabled,
    });
  }
  if (selectionType === "multi") {
    return MultiSelectColumn({
      cellClass,
      headerCellClass,
      isItemDisabled,
      allItemsSelected,
      onAllItemsSelectionChange,
      selectAllDisabled,
    });
  }
  return null;
};

export default SelectionColumn;
