import React from "react";
import SideNavigation from "@amzn/awsui-components-react/polaris/side-navigation";
import { useNavConfig, useOnFollowHandler } from "./hooks";

const LeftNavigation: React.FC = () => {
  const onFollowHandler = useOnFollowHandler();
  const { header, items, activeHref } = useNavConfig();

  return (
    <SideNavigation
      header={header}
      items={items}
      activeHref={activeHref}
      onFollow={onFollowHandler}
      data-testid="navigation"
    />
  );
};

export default LeftNavigation;
