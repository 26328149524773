import { formatToggled } from "./slice";
import { useAppDispatch } from "app/store";

/**
 * Hook for handling side navigation events
 */
export const useOnClickHandler = () => {
  const dispatch = useAppDispatch();

  return () => {
    dispatch(formatToggled());
  };
};
