import React from "react";
import Box from "@amzn/awsui-components-react/polaris/box";
import Link from "@amzn/awsui-components-react/polaris/link";
import styles from "./style.module.scss";

const Footer: React.FC = () => {
  return (
    <footer
      id="footer"
      className={styles.footer}
      data-testid="footer"
      role="contentinfo"
    >
      <div className="footer-content">
        <Box padding={{ right: "l" }}>
          <Link
            color="inverted"
            fontSize="body-s"
            target="_blank"
            variant="primary"
          >
            Amazon Confidential
          </Link>
        </Box>
      </div>
    </footer>
  );
};

export default Footer;
