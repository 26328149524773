import React from "react";
import { Hub } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "app/store";

/**
 * Hook for handling amplify auth events
 */
export const useOnAuthHandler = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    const authListener = (data: any) => {
      if (data.payload.event === "customOAuthState") {
        navigate(data.payload.data, { replace: true });
      }
    };

    Hub.listen("auth", authListener);

    return () => Hub.remove("auth", authListener);
  }, [navigate]);
};

/**
 * Hook to check if user is a super-admin
 */
export const useSuperAdminCheck = () => {
  const { roles } = useAppSelector((state) => state.auth);
  return (
    roles.includes("usetax-compliance-super-admin") ||
    roles.includes("usetax-audit-super-admin") ||
    roles.includes("usetax-system-account")
  );
};

export const useJeAccrualAdminCheck = () => {
  const { roles } = useAppSelector((state) => state.auth);
  return (
    roles.includes("usetax-compliance-jea-admin") ||
    roles.includes("usetax-system-account")
  );
};

export const useSysAdminCheck = () => {
  return useAppSelector((state) => state.auth).roles.includes(
    "usetax-sys-admin"
  );
};
