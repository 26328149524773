import { useLocalStorage } from "hooks";
import { SelectAllState, SortOrder } from "@amzn/polaris-data-grid";
import { Filter } from "components/column-sort-and-filter/interfaces";
import React from "react";
import { ColumnInterface } from "common/interfaces";
import { sortRows } from "components/column-sort-and-filter/utils";

/**
 * Add aria Labels to the column Definitions
 * @param columnDefinitions
 * @returns
 */
export const addColumnSortLabels = (columnDefinitions: any[]) =>
  columnDefinitions.map(
    (col: { sortingField: any; sortingComparator: any; header: any }) => ({
      ariaLabel:
        col.sortingField || col.sortingComparator
          ? (sortState: { sorted: any; descending: any }) =>
              headerLabel(col.header, sortState.sorted, sortState.descending)
          : undefined,
      ...col,
    })
  );

/**
 * Add widths to the columnDefinitions, useful when having re-sizeable columns
 * The updated widths will then be stored in local storage
 * @param storageKey
 * @param columnDefinitions
 * @returns
 */
export function useColumnWidths(storageKey: any, columnDefinitions: any) {
  const [widths, saveWidths] = useLocalStorage(storageKey, []);

  function handleWidthChange(event: any) {
    saveWidths(
      mapWithColumnDefinitionIds(
        columnDefinitions,
        "width",
        event.detail.widths
      )
    );
  }
  const memoDefinitions = React.useMemo(() => {
    return addToColumnDefinitions(columnDefinitions, "width", widths);
  }, [widths, columnDefinitions]);

  return [memoDefinitions, handleWidthChange];
}

/**
 * Function to return text to display on UI for the filtered items
 * @param count
 * @returns
 */
export const getFilterCounterText = (count: any) =>
  `${count} ${count === 1 ? "match" : "matches"}`;

/**
 * A generic function which will map id from the columnDefinations with the given property
 * @param columnDefinitions
 * @param propertyName
 * @param items
 * @returns
 */
export const mapWithColumnDefinitionIds = (
  columnDefinitions: any,
  propertyName: string,
  items: any
) =>
  columnDefinitions.map(({ id }: any, i: string | number) => ({
    id,
    [propertyName]: items[i],
  }));

/**
 * A generic function which will add the given property to columnDefinations
 * @param columnDefinitions
 * @param propertyName
 * @param columns
 * @returns
 */
export const addToColumnDefinitions = (
  columnDefinitions: any,
  propertyName: string,
  columns: any
) =>
  columnDefinitions.map((colDef: any) => {
    const column = columns && columns.find((col: any) => col.id === colDef.id);
    return {
      ...colDef,
      [propertyName]: (column && column[propertyName]) || colDef[propertyName],
    };
  });

/**
 * Label text for the sorted Column
 * @param title
 * @param sorted
 * @param descending
 * @returns
 */
export const headerLabel = (
  title: string,
  sorted: boolean,
  descending: boolean
) => {
  return `${title}, ${
    sorted ? `sorted ${descending ? "descending" : "ascending"}` : "not sorted"
  }.`;
};

export const sort = (
  sortProperty: string,
  sortOrder: SortOrder,
  data: readonly any[],
  columnDefinations: ColumnInterface<any>[]
) => {
  const column = columnDefinations.find((col) => col.id === sortProperty)!;
  return sortRows(data, sortProperty, sortOrder, column.sortTransform);
};

export const filter = (
  rows: readonly any[],
  filters: Filter,
  columnDefinations: ColumnInterface<any>[]
) => {
  const res = rows.filter((row: any) => {
    let result = true;
    filters.forEach((val, key) => {
      if (val.selectAllState === SelectAllState.NONE) {
        result = false;
        return;
      } else if (val.selectAllState === SelectAllState.ALL) {
        return;
      } else {
        const transform = columnDefinations.find(
          (col) => col.id === key
        )?.filterTransform;
        const value = (transform ? transform(row) : row[key]).toString();
        if (val.in) {
          result =
            result &&
            val.options.map((opt) => opt.value.toString()).includes(value);
        } else {
          result =
            result &&
            !val.options.map((opt) => opt.value.toString()).includes(value);
        }
      }
      if (!result) {
        return;
      }
    });
    return result;
  });
  return res;
};
