import { updateYear, updateMonth } from "./slice";
import { useAppDispatch, useAppSelector } from "app/store";

export const useDataOrchestrationYearMonthFilters = () => {
  const dispatch = useAppDispatch();
  const selector = useAppSelector(
    (state) => state.dataOrchestrationYearMonthFilters
  );

  return {
    dataOrchestrationYearMonthFilters: selector,
    updateYear: (payload: number) => dispatch(updateYear(payload)),
    updateMonth: (payload: string) => dispatch(updateMonth(payload)),
  };
};
