import { yearMonthUpdate } from "./slice";
import { yearsMonthsUpdate } from "./sliceMultiSelect";
import { useAppDispatch, useAppSelector } from "app/store";
import {
  ICalculateTaxesTableFiltersState,
  ICalculateTaxesTableMultiSelectFiltersState,
} from "./interfaces";

export const useCalculateTaxesTableFilters = () => {
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.calculateTaxesTableFilters);

  return {
    calculateTaxesTableFilters: selector,
    updateCalculateTaxesYearMonth: (
      payload: ICalculateTaxesTableFiltersState
    ) => dispatch(yearMonthUpdate(payload)),
  };
};

export const useCalculateTaxesTableMultiSelectFilters = () => {
  const dispatch = useAppDispatch();
  const selector = useAppSelector(
    (state) => state.calculateTaxesTableMultiSelectFilters
  );

  return {
    calculateTaxesTableMultiSelectFilters: selector,
    updateCalculateTaxesYearsMonths: (
      payload: ICalculateTaxesTableMultiSelectFiltersState
    ) => dispatch(yearsMonthsUpdate(payload)),
  };
};
