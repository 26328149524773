import {
  Box,
  Checkbox,
  CheckboxProps,
  NonCancelableCustomEvent,
  SpaceBetween,
} from "@amzn/awsui-components-react-v3/polaris";
import React from "react";
import { Option } from "../../interfaces/Option";
import styles from "../MultiSelect/multiSelect.module.scss";

interface MultiSelectOptionProps {
  value: string | number;
  label: string | JSX.Element;
  isChecked: boolean;
  onSelectionChange: (isChecked: boolean, option: Option) => void;
  isDisabled?: boolean;
}

const MultiSelectOption: React.FC<MultiSelectOptionProps> = ({
  value,
  label,
  isChecked,
  onSelectionChange,
  isDisabled = false,
}): JSX.Element => {
  return (
    <Box padding="xxs" className={isChecked ? styles["box-checked"] : styles.box}>
      <SpaceBetween direction="horizontal" size="xs">
        <Checkbox
          key={value}
          checked={isChecked}
          onChange={(event: NonCancelableCustomEvent<CheckboxProps.ChangeDetail>) => {
            onSelectionChange(event.detail.checked, { label, value });
          }}
          disabled={isDisabled}
        >
          {label}
        </Checkbox>
      </SpaceBetween>
    </Box>
  );
};

export default MultiSelectOption;
