import { mainApi } from "services/main";
import {
  IsWorkflowRunningRequest,
  IsWorkflowRunningResponse,
} from "./interfaces";

const extendedApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    isWorkflowRunning: builder.mutation<
      IsWorkflowRunningResponse,
      IsWorkflowRunningRequest
    >({
      query: (request?: IsWorkflowRunningRequest) => ({
        url: "workflow/isRunning",
        body: request,
        method: "POST",
      }),
    }),
  }),
  overrideExisting: true,
});

export const { useIsWorkflowRunningMutation } = extendedApi;
