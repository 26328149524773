import React from "react";
import styles from "./style.module.scss";
import Select from "@amzn/awsui-components-react/polaris/select";
import {
  MONTHS_FILTER,
  YEARS_FILTER,
} from "features/calculate-taxes-table-filters/constants";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import { useNotifications } from "features/notifications/hooks";
import { useApprovalsFilters } from "./hooks";

const ApprovalsFilters: React.FC = () => {
  const {
    approvalsFilters: { year, month },
    updateYear,
    updateMonth,
  } = useApprovalsFilters();

  const { clearNotifications } = useNotifications();

  return (
    <div className={styles.jeAccrualYearMonthFilters}>
      <Grid gridDefinition={[{ colspan: 5 }, { colspan: 7 }]}>
        <div>
          <Select
            selectedOption={{ label: year.toString(), value: year.toString() }}
            options={YEARS_FILTER}
            placeholder="Year"
            filteringType="auto"
            onChange={({ detail }) => {
              clearNotifications();
              updateYear(parseInt(detail.selectedOption.value!));
            }}
          />
        </div>
        <div>
          <Select
            selectedOption={{
              label: month,
              value: month,
            }}
            options={MONTHS_FILTER}
            placeholder="Month"
            filteringType="auto"
            onChange={({ detail }) => {
              clearNotifications();
              updateMonth(detail.selectedOption.value!);
            }}
          />
        </div>
      </Grid>
    </div>
  );
};
export default ApprovalsFilters;
