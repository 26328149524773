import React from "react";
import BreadcrumbGroup from "@amzn/awsui-components-react/polaris/breadcrumb-group";
import { useBreadcrumbs, useOnClickHandler } from "./hooks";

const Breadcrumbs: React.FC = () => {
  const { getBreadcrumbs } = useBreadcrumbs();
  const { items } = getBreadcrumbs();
  const onClickHandler = useOnClickHandler();

  return (
    <BreadcrumbGroup
      items={items}
      ariaLabel="Breadcrumbs"
      onClick={onClickHandler}
      data-testid="breadcrums"
    />
  );
};

export default Breadcrumbs;
