import React from "react";

import Header from "@amzn/awsui-components-react/polaris/header";

import { usePageHeader } from "./hooks";
import Info from "features/info";

const PageHeader: React.FC = () => {
  const { pageHeader } = usePageHeader();
  const { title, description, hasInfo, help, actions } = pageHeader;

  return (
    <Header
      variant="awsui-h1-sticky"
      description={description}
      info={hasInfo && <Info help={help} />}
      actions={actions}
      data-testid="page-header"
    >
      {title}
    </Header>
  );
};

export default PageHeader;
