import moment from "moment";

export const getPreviousYear = () => {
  return getCurrentYear() - 1;
};

export const getCurrentYear = () => {
  return new Date().getFullYear();
};

export const getCurrentMonth = () => {
  return new Date().toLocaleString("default", { month: "long" });
};

export const getPastYears = (count: number) => {
  const current = new Date().getFullYear();

  const years: number[] = [];
  for (let i = 0; i < count; i++) {
    years.push(current - i);
  }

  return years;
};

export const getNextYear = () => {
  return getCurrentYear() + 1;
};

export const getYearMonthForUseTaxCalculationFilter = (): [number, string] => {
  const day = moment();
  day.subtract(1, "months");
  return [day.year(), day.format("MMMM")];
};

export const getNumericMonthValue = (month: string) => {
  return String(
    [
      "JANUARY",
      "FEBRUARY",
      "MARCH",
      "APRIL",
      "MAY",
      "JUNE",
      "JULY",
      "AUGUST",
      "SEPTEMBER",
      "OCTOBER",
      "NOVEMBER",
      "DECEMBER",
    ].indexOf(month) + 1
  ).padStart(2, "0");
};
