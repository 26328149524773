import { useEffect } from "react";
import { DataGridHandle } from "react-data-grid";

/**
 * Fixes the layout of the portal target element. It moves it inside the grid element.
 *
 * @param gridHandle The data grid handle.
 * @param portalElement The portal target element.
 */
const useEditorTargetLayout = (
  gridHandle: DataGridHandle | null,
  portalElement: Element | null
): void => {
  useEffect(() => {
    if (gridHandle?.element && portalElement) {
      gridHandle.element.insertBefore(portalElement, gridHandle.element.firstChild);
    }
  }, [gridHandle?.element, portalElement]);
};

export default useEditorTargetLayout;
