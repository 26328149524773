import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { useTranslation } from "react-i18next";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import Router from "./router";
import { store } from "./store";

import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";

import Amplify from "components/amplify";
import ErrorBoundary from "components/error-boundary";
import Footer from "components/footer";
import Header from "components/header";
import LeftNavigation from "components/left-navigation";
import Loader from "components/loader";
import PageHeader, { usePageHeader } from "features/page-header";

import Breadcrumbs from "features/breadcrumbs";
import DetailsPanel from "features/details/DetailsPanel";
import Notifications from "features/notifications";
import { AuthN } from "features/auth";
import { useSettings } from "features/settings";
import { useInfo } from "features/info";
import { useDetails } from "features/details";

import styles from "./style.module.scss";
import "./i18n";
import EvidentlyWrapper from "features/evidently/EvidentlyWrapper";

const App: React.FC = (): JSX.Element => {
  useSettings();
  useTranslation();

  const [navigationOpen, setNavigationOpen] = React.useState<boolean>(true);
  const { pageHeader } = usePageHeader();
  const { info, setOpenInfo } = useInfo();
  const { details, setOpenDetails, setResizeDetails } = useDetails();

  return (
    <Amplify>
      <AuthN>
        <EvidentlyWrapper>
          <div className={styles.awsui}>
            <ErrorBoundary>
              <Header />

              <AppLayout
                headerSelector="#header"
                stickyNotifications={true}
                notifications={<Notifications />}
                navigation={<LeftNavigation />}
                navigationOpen={navigationOpen}
                onNavigationChange={({ detail }) =>
                  setNavigationOpen(detail.open)
                }
                breadcrumbs={<Breadcrumbs />}
                content={
                  <ErrorBoundary>
                    {pageHeader.requireContentLayout && (
                      <ContentLayout header={<PageHeader />}>
                        <Router />
                      </ContentLayout>
                    )}

                    {!pageHeader.requireContentLayout && <Router />}
                  </ErrorBoundary>
                }
                tools={info.tools}
                toolsOpen={info.toolsOpen}
                onToolsChange={({ detail }) => setOpenInfo(detail.open)}
                splitPanelOpen={details.open}
                onSplitPanelToggle={({ detail }) => setOpenDetails(detail.open)}
                splitPanelSize={details.size}
                onSplitPanelResize={({ detail }) =>
                  setResizeDetails(detail.size)
                }
                splitPanel={<DetailsPanel />}
                footerSelector="#footer"
              />

              <Footer />
            </ErrorBoundary>
          </div>
        </EvidentlyWrapper>
      </AuthN>
    </Amplify>
  );
};

const AppProvider: React.FC = (): JSX.Element => {
  return (
    <Provider store={store}>
      <Suspense fallback={<Loader loadingText="Loading..." />}>
        <DndProvider backend={HTML5Backend}>
          <App />
        </DndProvider>
      </Suspense>
    </Provider>
  );
};

export default AppProvider;
