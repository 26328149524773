import React from "react";
import styles from "./ShrinkSVG.module.scss";

export const ShrinkSvg = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g id="icon">
        <polyline className={styles["shrink-svg"]} points="15 6 10 6 10 1" />
        <polyline className={styles["shrink-svg"]} points="1 6 6 6 6 1" />
        <polyline className={styles["shrink-svg"]} points="1 10 6 10 6 15" />
        <polyline className={styles["shrink-svg"]} points="15 10 10 10 10 15" />
        <line className={styles["shrink-svg"]} x1="1" y1="1" x2="6" y2="6" />
        <line className={styles["shrink-svg"]} x1="15" y1="1" x2="10" y2="6" />
        <line className={styles["shrink-svg"]} x1="6" y1="10" x2="1" y2="15" />
        <line className={styles["shrink-svg"]} x1="10" y1="10" x2="15" y2="15" />
      </g>
    </svg>
  );
};

export default ShrinkSvg;
