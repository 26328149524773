import React from "react";
import { configureAmplify } from "./utils";
import ApiIndicator from "components/api-indicator";
import { useGetSettingsQuery } from "features/settings";

const Amplify: React.FC = ({ children }) => {
  const { data, error, isLoading, isSuccess, isError } = useGetSettingsQuery();

  return (
    <>
      <ApiIndicator
        isLoading={isLoading}
        isError={isError}
        isAlertError={true}
        error={error}
      />
      {isSuccess && data && configureAmplify(data) && (
        <React.Fragment>{children}</React.Fragment>
      )}
    </>
  );
};

export default Amplify;
