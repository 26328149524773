import { SideNavigationProps } from "@amzn/awsui-components-react/polaris/side-navigation";
import {
  JE_ACCRUAL_FEATURE,
  BUSINESS_RULE_FEATURE,
  DATA_ORCHESTRATION_FEATURE,
  UTA_VISUALIZATION_REPORTING,
  JE_ACCRUAL_APPROVAL_FEATURE,
} from "features/evidently/constants";
import { useVariation } from "features/evidently";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useApprovalsFilters } from "../../features/approvals-filters";
import { useJeAccrualYearMonthFilters } from "../../features/je-accrual-year-month-filters";

/**
 * Hook for handling side navigation events
 */
export const useOnFollowHandler = () => {
  const navigate = useNavigate();

  return (event: CustomEvent<SideNavigationProps.FollowDetail>) => {
    if (!event.detail.external) {
      event.preventDefault();
      navigate(event.detail.href, { replace: true });
    }
  };
};

export const useNavConfig = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { isEnabled: isJEAccrualEnabled } = useVariation(JE_ACCRUAL_FEATURE);
  const { isEnabled: isBusinessRulesEnabled } = useVariation(
    BUSINESS_RULE_FEATURE
  );
  const { isEnabled: isDataOrchestrationEnabled } = useVariation(
    DATA_ORCHESTRATION_FEATURE
  );
  const { isEnabled: isUTAVREnabled } = useVariation(
    UTA_VISUALIZATION_REPORTING
  );
  const { isEnabled: isJEApprovalEnabled } = useVariation(
    JE_ACCRUAL_APPROVAL_FEATURE
  );
  const { approvalsFilters } = useApprovalsFilters();
  const { jeAccrualYearMonthFilters } = useJeAccrualYearMonthFilters();

  const utaVRItem = isUTAVREnabled
    ? [
        {
          type: "link",
          text: t("pages.home.reporting"),
          href: "/reporting",
        },
      ]
    : [];

  const businessRulesItems = isBusinessRulesEnabled
    ? [
        {
          type: "link",
          text: t("pages.home.business_rules"),
          href: "/business-rules",
        },
      ]
    : [];

  const dataOrchestrationItems = isDataOrchestrationEnabled
    ? [
        {
          type: "link",
          text: t("pages.home.data_orchestration"),
          href: "/data-orchestration",
        },
      ]
    : [];

  const jeAccrualItem = isJEAccrualEnabled
    ? [
        {
          type: "link",
          text: t("pages.home.je_accrual"),
          href: `/je-accrual?year=${
            jeAccrualYearMonthFilters.year
          }&month=${jeAccrualYearMonthFilters.month.toUpperCase()}`,
        },
      ]
    : [];

  const approvalsItem = isJEApprovalEnabled
    ? [
        {
          type: "link",
          text: t("pages.home.approvals"),
          href: `/approvals?year=${
            approvalsFilters.year
          }&month=${approvalsFilters.month.toUpperCase()}`,
        },
      ]
    : [];

  return {
    header: {
      href: "",
      text: t("left_nav.title"),
    } as SideNavigationProps.Header,
    items: [
      { type: "link", text: t("pages.home.title"), href: "/" },
      {
        type: "link",
        text: t("pages.home.calculate_taxes"),
        href: "/calculate-taxes",
      },
      ...jeAccrualItem,
      ...businessRulesItems,
      ...dataOrchestrationItems,
      ...utaVRItem,
      ...approvalsItem,
    ] as SideNavigationProps.Item[],
    activeHref: location.pathname + location.search,
  };
};
