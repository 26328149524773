import axios from "axios";

/**
 * Uploads a file to S3 using presigned Url
 * @param presignedUrl
 * @param file
 * @returns True if the upload to S3 is success else False.
 */
export const uploadToS3UsingPresignedUrl = async (
  presignedUrl: string,
  file: File | null
) => {
  if (file === null) return;
  try {
    await axios.put(presignedUrl, file, {
      headers: {
        "Content-Type": file.type,
      },
    });
    return true;
  } catch {
    return false;
  }
};
