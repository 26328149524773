import { Ref, useRef, useState } from "react";

interface UseFullscreenProps {
  initialFullscreen: boolean;
}

export interface FullscreenHandle {
  isFullscreen: boolean;
  element: Ref<HTMLDivElement>;
  toggle: () => void;
  enter: () => void;
  exit: () => void;
}

export const useFullscreen = ({ initialFullscreen }: UseFullscreenProps): FullscreenHandle => {
  const [isFullscreen, setIsFullscreen] = useState<boolean>(initialFullscreen);
  const element = useRef<HTMLDivElement>(null);

  const toggle = (): void => {
    setIsFullscreen(!isFullscreen);
  };

  const enter = (): void => {
    setIsFullscreen(true);
  };

  const exit = (): void => {
    setIsFullscreen(false);
  };

  return {
    isFullscreen,
    element,
    toggle,
    enter,
    exit,
  };
};
