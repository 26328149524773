import { Button, Container } from "@amzn/awsui-components-react-v3";
import React, { useRef, useEffect } from "react";
import Popup from "reactjs-popup";
import FieldFilter from "../../elements/FieldFilter/FieldFilter";
import FieldSort from "../../elements/FieldSort/FieldSort";
import SortAndFilterFooter from "../../elements/SortAndFilterFooter/SortAndFilterFooter";
import SortOrder from "../../enums/SortOrder";
import { useMergedFilterOptions } from "../../hooks/useMergedFilterOptions";
import useSelectedSortAndFilterOptions from "../../hooks/useSelectedSortAndFilterOptions";
import SelectAllState from "../../enums/SelectAllState";
import DataGridSortAndFilterProps from "../../interfaces/DataGridSortAndFilterProps";
import { Option } from "../../interfaces/Option";
import {
  calculateSelectAllStateFromCheckedOptions,
  calculateSelectAllStateFromDisplayedResultsState,
  getCheckedOptions,
} from "../../utils/sortAndFilterUtils";
import "./dataGridSortAndFilter.module.css";

const DEFAULT_TRIGGER_ELEMENT: JSX.Element = (
  <div styleName="popover-element">
    <Button data-testid="filter-button" variant="inline-icon" iconName="filter" />
  </div>
);

const DataGridSortAndFilter: React.FC<DataGridSortAndFilterProps> = ({
  sortProperty,
  filterProperty,
  sortHeader,
  sortAscendingLabel,
  sortDescendingLabel,
  filterHeader,
  filterOptions,
  selectedFilterOptions,
  isLoadingOptions,
  emptyOptions,
  confirmLabel,
  cancelLabel,
  isVisible = false,
  onOpen,
  onClose,
  onFilterTextChange,
  onDelayedFilterTextChange,
  onConfirm,
  onCancel,
  positionAt,
  keepComponentInside,
  triggerElement = DEFAULT_TRIGGER_ELEMENT,
  selectedSortOrder = SortOrder.NONE,
  filterText,
  filterPlaceholderText,
  selectAllLabel,
  selectAllDisplayedResultsLabel,
  selectAllDisplayedResultsState = SelectAllState.INITIAL,
  loadingOptions,
  isSortEnabled,
  isFilterEnabled,
  className,
  multiSelectClassName,
  offsetX = 0,
  offsetY = 0,
  focusOnLoad = false,
  isSingleSelect = false,
}): JSX.Element => {
  const {
    selectedSortOrder: currentSortOrder,
    selectedFilterOptions: currentSelectedFilterOptions,
    selectAllDisplayedResultsState: currentSelectAllDisplayedResultsState,
    updateSortOrder,
    updateSelectedFilterOptions,
    reset,
  } = useSelectedSortAndFilterOptions(
    selectedSortOrder,
    selectedFilterOptions,
    selectAllDisplayedResultsState
  );

  const hasFilterText = !!filterText;

  const mergedFilterOptions = useMergedFilterOptions({
    filterOptions,
    selectedFilterOptions: currentSelectedFilterOptions,
    hasFilterText,
    isVisible,
  });

  const checkedOptions = getCheckedOptions(currentSelectedFilterOptions, mergedFilterOptions);
  const popupBodyReference = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (focusOnLoad && !isLoadingOptions && popupBodyReference.current) {
      popupBodyReference.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }
  }, [focusOnLoad, isLoadingOptions, mergedFilterOptions]);

  return (
    <Popup
      trigger={triggerElement}
      on="click"
      position={positionAt}
      keepTooltipInside={keepComponentInside}
      open={isVisible}
      onOpen={() => {
        onOpen();
      }}
      onClose={() => {
        reset();
        onClose();
      }}
      arrow={false}
      offsetX={offsetX}
      offsetY={offsetY}
    >
      <div
        styleName="popup-body"
        data-testid="popup-body"
        onPointerDown={(event) => {
          // Avoid react data grid trying to resize on pointer down on popup
          // TODO: Raise a PR with React data grid lib to check complete x and y bounds (lower and upper) for resize events.
          // https://github.com/adazzle/react-data-grid/blob/3d407a8684a6f342fb31e977da0c790b11ddd10b/src/HeaderCell.tsx#L51
          event.stopPropagation();
        }}
        ref={popupBodyReference}
      >
        <Container
          disableContentPaddings
          footer={
            <SortAndFilterFooter
              confirmLabel={confirmLabel}
              cancelLabel={cancelLabel}
              onConfirm={() => {
                onClose();
                onConfirm(
                  currentSortOrder,
                  sortProperty,
                  currentSelectedFilterOptions,
                  filterProperty
                );
              }}
              onCancel={() => {
                reset();
                onClose();
                onCancel();
              }}
            />
          }
          className={className}
        >
          {isSortEnabled && (
            <FieldSort
              header={sortHeader}
              sortOrder={currentSortOrder}
              ascendingLabel={sortAscendingLabel}
              descendingLabel={sortDescendingLabel}
              onSortOrderChange={(sort: SortOrder) => {
                updateSortOrder(sort);
              }}
            />
          )}
          {isFilterEnabled && (
            <FieldFilter
              header={filterHeader}
              isLoadingOptions={isLoadingOptions}
              options={mergedFilterOptions.options}
              selectedOptions={checkedOptions}
              selectAllState={
                hasFilterText
                  ? calculateSelectAllStateFromDisplayedResultsState(
                      currentSelectedFilterOptions.selectAllState,
                      currentSelectAllDisplayedResultsState
                    )
                  : currentSelectedFilterOptions.selectAllState
              }
              selectAllDisplayedResultsState={
                hasFilterText
                  ? calculateSelectAllStateFromCheckedOptions(
                      checkedOptions,
                      mergedFilterOptions.options
                    )
                  : currentSelectedFilterOptions.selectAllState
              }
              filterText={filterText}
              selectAllLabel={selectAllLabel}
              selectAllDisplayedResultsLabel={selectAllDisplayedResultsLabel}
              placeholderFilterText={filterPlaceholderText}
              loadingOptions={loadingOptions}
              emptyOptions={emptyOptions}
              onTextChange={(text: string) => {
                onFilterTextChange(text, filterProperty);
              }}
              onDelayedTextChange={(text: string) => {
                onDelayedFilterTextChange(text, filterProperty);
              }}
              onSelectionUpdate={(isChecked: boolean, selectedOption: Option) => {
                updateSelectedFilterOptions({
                  isChecked,
                  selectedOption,
                  filterOptions: mergedFilterOptions,
                  hasFilterText,
                });
              }}
              multiSelectClassName={multiSelectClassName}
              isSingleSelect={isSingleSelect}
            />
          )}
        </Container>
      </div>
    </Popup>
  );
};

export default DataGridSortAndFilter;
