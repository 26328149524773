import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICalculateTaxesTableFiltersState } from "./interfaces";
import { getYearMonthForUseTaxCalculationFilter } from "../../utils";

const yearAndMonth = getYearMonthForUseTaxCalculationFilter();
const initialState: ICalculateTaxesTableFiltersState = {
  year: yearAndMonth[0],
  month: yearAndMonth[1],
};

export const calculateTaxesTableFiltersSlice = createSlice({
  name: "calculateTaxesTableFilters",
  initialState,
  reducers: {
    yearMonthUpdate: (
      state,
      action: PayloadAction<ICalculateTaxesTableFiltersState>
    ) => {
      state.year = action.payload.year;
      state.month = action.payload.month;
    },
  },
});

export const { yearMonthUpdate } = calculateTaxesTableFiltersSlice.actions;

export default calculateTaxesTableFiltersSlice.reducer;
