import { useIsWorkflowRunningMutation } from "./endpoint";
import {
  IsWorkflowRunningRequest,
  IsWorkflowRunningResponse,
} from "./interfaces";

const DEFAULT_POLLING_TIME_IN_SECONDS = 30;

export const useWorkflowPolling = () => {
  const [callIsWorkflowRunning] = useIsWorkflowRunningMutation();

  const isWorkflowRunning = async (
    request: IsWorkflowRunningRequest
  ): Promise<IsWorkflowRunningResponse> => {
    return await callIsWorkflowRunning(request).unwrap();
  };

  const pollWorkflow = async (
    request: IsWorkflowRunningRequest,
    pollingTime = DEFAULT_POLLING_TIME_IN_SECONDS
  ): Promise<IsWorkflowRunningResponse> => {
    const startTimeInSeconds = new Date().getSeconds();
    let response;
    do {
      response = await isWorkflowRunning(request);
      if (response.running) break;
    } while (new Date().getSeconds() - startTimeInSeconds < pollingTime);
    return response;
  };

  return { pollWorkflow, isWorkflowRunning };
};
