import React from "react";

import SplitPanel from "@amzn/awsui-components-react/polaris/split-panel";

import { splitPanelI18nStrings } from "utils/i18n-strings/split-panel";

import { useDetails } from "./hooks";

const DetailsPanel: React.FC = () => {
  const { details } = useDetails();

  if (!details.header || !details.content) {
    return <></>;
  }

  return (
    <SplitPanel
      header={details.header}
      i18nStrings={splitPanelI18nStrings}
      closeBehavior={details.closeBehavior ?? "hide"}
    >
      {details.content}
    </SplitPanel>
  );
};

export default DetailsPanel;
