import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IDetailsPanelProps, IDetailsState } from "./interfaces";

const initialState: IDetailsState = {
  content: null,
  size: 0,
  open: false,
};

export const detailsSlice = createSlice({
  name: "details",
  initialState,
  reducers: {
    detailsUpdated: (state, action: PayloadAction<IDetailsPanelProps>) => {
      state.header = action.payload.header;
      state.content = action.payload.details;
    },
    detailsOpened: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    detailsResized: (state, action: PayloadAction<number>) => {
      state.size = action.payload;
    },
  },
});

export const { detailsUpdated, detailsOpened, detailsResized } =
  detailsSlice.actions;

export default detailsSlice.reducer;
