export const JE_ACCRUAL_FEATURE = "JEAccrual-Assignment";
export const CONVERSION_TO_XLSX_FEATURE = "ConversionToXLSX";
export const USE_TAX_SUMMARY_FEATURE = "UsetaxSummary";
export const UTC_MULTISELECT_FEATURE = "UTCMultiSelectFeature";
export const DATA_ORCHESTRATION_FEATURE = "Data-Orchestration";
export const BUSINESS_RULE_FEATURE = "Business-Rules";

export function substringAfter(str: string, separator: string) {
  let n = str.lastIndexOf(separator);
  return str.substring(n + separator.length);
}
