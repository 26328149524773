import { createSlice } from "@reduxjs/toolkit";
import { loadVariations } from "./thunk";
import { EvidentlyFeature } from "./interfaces";

const initialState: {
  isLoading: boolean;
  isError: boolean;
  features: Record<string, EvidentlyFeature>;
} = {
  isLoading: false,
  isError: false,
  features: {},
};

const slice = createSlice({
  name: "evidently",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadVariations.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(loadVariations.fulfilled, (state, action) => {
      state.features = action.payload.features;
      state.isLoading = false;
    });

    builder.addCase(loadVariations.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export default slice.reducer;
