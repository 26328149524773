import { Container, Spinner } from "@amzn/awsui-components-react-v3";
import React, { useRef } from "react";
import ReactDataGrid, { DataGridHandle, EditorProps } from "react-data-grid";
import { Column } from "react-data-grid/lib/index";
import useDragToFill from "../../hooks/useDragToFill";
import useCopyPaste from "../../hooks/useCopyPaste";
import useEditorTargetLayout from "../../hooks/useEditorTargetLayout";
import useSelection from "../../hooks/useSelection";
import { CellPopover } from "../../elements/cell-popover/CellPopover";
import { SelectionColumn } from "../../elements/selection-columns/SelectionColumns";
import ToolsHeader from "../../elements/tools-header/ToolsHeader";
import { CellPopoverProps, PopoverPlacement } from "../../interfaces/CellPopoverProps";
import { ColumnDefinition } from "../../interfaces/ColumnDefinition";
import { DataGridProps } from "../../interfaces/DataGridProps";
import { PortalProps } from "../../interfaces/PortalProps";
import {
  DEFAULT_HEADER_ROW_HEIGHT,
  DEFAULT_ROW_HEIGHTS,
  getCellElement,
  getCSSPropertiesForCellPortal,
  getTableHeightClassName,
} from "../../utils/dataGridUtils";
import styles from "./DataGrid.module.scss";

export const DataGrid = <T extends unknown>({
  header,
  footer,
  empty = "",
  items = [],
  loading = false,
  loadingData = <Spinner size="large" />,
  columnDefinitions,
  pagination,
  preferences,
  fullscreenButton,
  fullscreenHandle,
  resizableColumns = false,
  visibleColumns,
  columnHeaderRenderer,
  rowKeyProvider,
  selectionType,
  selectedItems = new Set([]),
  isItemDisabled,
  onSelectionChange,
  onFill,
  onCopy,
  onPaste,
  cellPopoverProps,
  stickyHeader = false,
  className = "",
  rowHeightSize = "m",
  disableVirtualization = false,
}: DataGridProps<T>): JSX.Element => {
  const gridReference = useRef<DataGridHandle>(null);
  const portalReference = useRef<HTMLDivElement>(null);

  const { handleFill, onRowsChange } = useDragToFill(onFill);

  const headerCellClass = styles["header-cell"];
  const cellClass = styles.cell;
  const isVisible = (column: ColumnDefinition<T>): boolean => {
    return !visibleColumns || visibleColumns.includes(column.id);
  };

  const getCellPopoverPortalProps = (popoverProps: CellPopoverProps): PortalProps | undefined => {
    const portalTarget = popoverProps.popoverTarget ?? portalReference.current;
    if (!portalTarget) {
      throw new Error("No DOM element available to attach a popover.");
    }
    const cellElement = getCellElement(
      gridReference.current,
      popoverProps.rowIdx + 1, // Add one for zero-based index correction
      popoverProps.columnIdx + 1 // Add one for zero-based index correction
    );
    const cssProps = getCSSPropertiesForCellPortal({
      portalTarget,
      cellElement,
      placement: popoverProps.placement,
      rowHeightSize,
    });
    return {
      portalTarget,
      cssProps,
    } as PortalProps;
  };

  const cellPopoverPortalProps = cellPopoverProps
    ? getCellPopoverPortalProps(cellPopoverProps)
    : undefined;

  const toReactColumnDefinition = (column: ColumnDefinition<T>): Column<T> => {
    const Editor = ({
      column: calculatedColumn,
      row,
      onRowChange,
      onClose,
    }: EditorProps<T>): JSX.Element => {
      const cellElement = getCellElement(
        gridReference.current,
        items.indexOf(row) + 2, // Add one for the header and one zero-based index correction
        calculatedColumn.idx + 1 // Add one for zero-based index correction
      );
      const cssProps = getCSSPropertiesForCellPortal({
        portalTarget: portalReference.current as HTMLElement,
        cellElement: cellElement as HTMLElement,
        placement: PopoverPlacement.below,
        rowHeightSize,
      });
      return (
        <>
          {column.editor &&
            column.editor({
              row,
              column,
              onRowChange,
              onClose,
              portalProps: {
                portalTarget: portalReference.current as HTMLElement,
                cssProps,
              } as PortalProps,
            })}
        </>
      );
    };

    return {
      key: column.id,
      name: column.id,
      cellClass: (row) =>
        `${cellClass} ${column.cellContainerClass && column.cellContainerClass(row)}`,
      formatter: ({ row }) => <>{column.cell(row)}</>,
      minWidth: column.minWidth,
      width: column.width,
      maxWidth: column.maxWidth,
      resizable: resizableColumns,
      headerCellClass,
      headerRenderer: () => {
        return (
          <div title={column.label}>
            {(columnHeaderRenderer && columnHeaderRenderer(column)) || column.label}
            <span className={styles.resizer}></span>
          </div>
        );
      },
      editable: column.editable,
      editor: Editor,
      editorOptions: {
        editOnClick: !column.editorOptions?.editOnDoubleClick,
        renderFormatter: !column.editorOptions?.renderWithoutCellContent,
      },
    };
  };

  const {
    allItemsSelected,
    selectAllDisabled,
    selectionHandler,
    onAllItemsSelectionChange,
  } = useSelection({
    items,
    selectionType,
    selectedItems,
    onSelectionChange,
    rowKeyProvider,
    isItemDisabled,
  });

  const getTransformedColumns = (): Column<T>[] => {
    const transformedColumns = columnDefinitions.filter(isVisible).map(toReactColumnDefinition);
    const selectionColumn = SelectionColumn<T>({
      selectionType,
      cellClass,
      headerCellClass,
      isItemDisabled,
      allItemsSelected,
      onAllItemsSelectionChange,
      selectAllDisabled,
    });
    if (selectionColumn) {
      return [selectionColumn, ...transformedColumns];
    }
    return transformedColumns;
  };

  const transformedColumns = getTransformedColumns();

  const { handlePaste } = useCopyPaste<T>({
    columnDefinitions: transformedColumns,
    items,
    gridHandle: gridReference.current,
    portalElement: portalReference.current,
    onCopy,
    onPaste,
  });

  useEditorTargetLayout(gridReference.current, portalReference.current);

  return (
    <Container
      header={
        <ToolsHeader
          header={header}
          pagination={pagination}
          preferences={preferences}
          fullscreenButton={fullscreenButton}
        />
      }
      footer={footer}
      disableContentPaddings
    >
      {/* popup-root is the id used by the popup lib as the container for popup */}
      <div id="popup-root" />
      <div ref={portalReference} className={styles["editor-target"]} />
      {cellPopoverProps && cellPopoverProps.isVisible && cellPopoverPortalProps && (
        <CellPopover portalProps={cellPopoverPortalProps} content={cellPopoverProps.content} />
      )}
      <div
        className={`${
          styles[
            getTableHeightClassName({
              rowsCount: items.length,
              isFullscreen: fullscreenHandle?.isFullscreen,
              isHeaderSticky: stickyHeader,
              rowHeightSize,
            })
          ]
        } ${className}`}
      >
        <ReactDataGrid<T>
          ref={gridReference}
          className="rdg-light"
          rows={!loading ? items : []}
          columns={transformedColumns}
          headerRowHeight={DEFAULT_HEADER_ROW_HEIGHT}
          rowHeight={DEFAULT_ROW_HEIGHTS[rowHeightSize]}
          rowClass={() => styles.row}
          noRowsFallback={
            <div className={styles["blank-state"]}>{loading ? loadingData : empty}</div>
          }
          rowKeyGetter={rowKeyProvider}
          selectedRows={selectedItems}
          onSelectedRowsChange={selectionHandler}
          onFill={handleFill}
          onPaste={handlePaste}
          onRowsChange={onRowsChange}
          enableVirtualization={!disableVirtualization}
        />
      </div>
    </Container>
  );
};

export default DataGrid;
