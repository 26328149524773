import React from "react";
import { Trans, useTranslation } from "react-i18next";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import { LOG } from "utils";
import { useNotifications } from "features/notifications";
import { IApiIndicatorProps } from "./interfaces";
import { getErrorMessage } from "./utils";

const ApiIndicator: React.FC<IApiIndicatorProps> = (
  props: IApiIndicatorProps
) => {
  const { t } = useTranslation();
  const title = t("components.api_indicator.title");
  const message = getErrorMessage(props.error);
  const { addNotification } = useNotifications();

  React.useEffect(() => {
    if (props.isError) {
      addNotification({
        type: "error",
        header: title,
        content: message,
        dismissible: true,
      });
      LOG.error(message);
    }
  }, [props.isError, message]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {props.isUninitialized && (
        <StatusIndicator type="pending">
          <Trans>components.api_indicator.uninitialized</Trans>
        </StatusIndicator>
      )}
      {props.isLoading && (
        <StatusIndicator type="loading">
          <Trans>components.api_indicator.loading</Trans>
        </StatusIndicator>
      )}
      {props.isFetching && (
        <StatusIndicator type="in-progress">
          <Trans>components.api_indicator.refetching</Trans>
        </StatusIndicator>
      )}
      {props.isError && props.isAlertError && (
        <Alert visible={true} type="error" header={title}></Alert>
      )}
      {props.isError && !props.isAlertError && (
        <StatusIndicator type="error">{title}</StatusIndicator>
      )}
    </>
  );
};

export default ApiIndicator;
