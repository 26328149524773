import { useCallback, useEffect } from "react";
import { Column, DataGridHandle, PasteEvent as RDGPasteEvent } from "react-data-grid";
import { CopyEvent, PasteEvent } from "../interfaces/CopyPasteEvents";
import { getSelectedCell } from "../utils/dataGridUtils";

export interface UseCopyPasteRequest<T> {
  columnDefinitions: ReadonlyArray<Column<T>>;
  items: ReadonlyArray<T>;
  gridHandle: DataGridHandle | null;
  portalElement: Element | null;
  onCopy?: (event: CopyEvent<T>) => void;
  onPaste?: (event: PasteEvent<T>) => T;
}

interface UseCopyPasteResult<T> {
  handlePaste: ((event: RDGPasteEvent<T>) => T) | undefined;
}

const isInvalidCellCopyEvent = (target: EventTarget | null, portal: Element | null): boolean => {
  return !(target instanceof Element) || (!target.closest(".rdg-cell") && !portal?.hasChildNodes());
};

const useCopyPaste = <T extends unknown>({
  columnDefinitions,
  items,
  gridHandle,
  portalElement,
  onCopy,
  onPaste,
}: UseCopyPasteRequest<T>): UseCopyPasteResult<T> => {
  const onKeyDown = useCallback(
    (event: KeyboardEvent): void => {
      if (!onCopy || isInvalidCellCopyEvent(event.target, portalElement)) {
        return;
      }

      const { code, metaKey, ctrlKey } = event;
      if ((metaKey || ctrlKey) && code === "KeyC") {
        const selectedCell = getSelectedCell(gridHandle);
        if (selectedCell) {
          onCopy({
            sourceRow: items[selectedCell.rowIdx],
            sourceColumnKey: columnDefinitions[selectedCell.colIdx].key,
          });
        }
      }
    },
    [gridHandle, portalElement, columnDefinitions, items, onCopy]
  );

  useEffect(() => {
    if (onCopy) {
      window.addEventListener("keydown", onKeyDown);
    }

    return () => {
      if (onCopy) {
        window.removeEventListener("keydown", onKeyDown);
      }
    };
  }, [onCopy, onKeyDown]);

  return {
    handlePaste: onPaste,
  };
};

export default useCopyPaste;
