import { MULTIPLE_STATE_ENTITY } from "components/upload-tax-file-modal/constants";
import { months } from "moment";

/* This pattern extracts 3 inputs from the string. The string should be in the format of
"{2 digit number}-{4 digit number}-{any alphanumeric string with optional hyphens}-Use-Input.csv"
Examples:
  03-2021-NY-HK-Use-Input.xlsx
  03-2021-NY-HK-Use-Output.xlsx
  03-2021-NY-HK-Use-xyz.xlsx
  23-2022-Multiple-Use-Input.csv
  67-2023-NY-FL-HK-DC-Use-Input.csv
*/
const filenamePattern = /(\d{2})-(\d{4})-([A-Z0-9-]+)-Use-([A-Za-z]+)./i;

// \d{2}-\d{4}-(Multiple|([A-Za-z]{2}|NOSTATE)-[A-Za-z0-9]+)-Use-([A-Za-z]+).csv

/* This pattern extracts 2 inputs from the string. The string should be in the format of
"{2 letter word}-{any alphanumeric string}"
Examples:
  NY-HK
  FL-2D
  DC-BB012
*/
const singleStateAndEntityPattern = /\b([A-Z]{2}|NOSTATE)\b-([A-Z0-9]+)/i;

export const extractUseTaxCalculationInputFromFilename = (
  filename: string
):
  | [{ year: string; month: string; state: string; entity: string }, boolean]
  | undefined => {
  const result = filename.match(filenamePattern);
  // result will in the format [{matched_pattern}, {month}, {year}, {stateEntity}] if the pattern matches or else null
  if (result && parseInt(result[1]) < 13) {
    const month = months(parseInt(result[1]) - 1); // months start from 0 index
    const year = result[2];
    // stateEntityResult will in the format [{matched_pattern}, {state}, {entity}] if the pattern matches or else null
    const stateEntityResult = result[3].match(singleStateAndEntityPattern);
    let state = "";
    let entity = "";
    let checked = false;
    if (stateEntityResult) {
      state = stateEntityResult[1];
      entity = stateEntityResult[2];
    } else if (result[3] === "Multiple") {
      checked = true;
      state = MULTIPLE_STATE_ENTITY;
      entity = MULTIPLE_STATE_ENTITY;
    }
    return [{ year, month, state, entity }, checked];
  }
};
