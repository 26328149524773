import {
  Box,
  Container,
  Header,
  SpaceBetween,
  Spinner,
} from "@amzn/awsui-components-react-v3/polaris";
import React from "react";
import SelectAllState from "../../enums/SelectAllState";
import { Option } from "../../interfaces/Option";
import MultiSelect from "../MultiSelect/MultiSelect";
import SearchBar from "../SearchBar/SearchBar";
import SingleSelect from "../SingleSelect/SingleSelect";

interface FieldFilterProps {
  header: React.ReactNode;
  isLoadingOptions: boolean;
  options: Option[];
  selectedOptions: Option[];
  selectAllState: SelectAllState;
  selectAllDisplayedResultsState: SelectAllState;
  emptyOptions: string | JSX.Element;
  onTextChange: (text: string) => void;
  onDelayedTextChange: (text: string) => void;
  onSelectionUpdate: (isChecked: boolean, option: Option) => void;
  filterText?: string;
  selectAllLabel?: string;
  selectAllDisplayedResultsLabel?: string;
  placeholderFilterText?: string;
  loadingOptions?: string | JSX.Element;
  multiSelectClassName?: string;
  isSingleSelect?: boolean;
}

const DISPLAY_LOADING_OPTIONS: JSX.Element = <Spinner />;

const FieldFilter: React.FC<FieldFilterProps> = ({
  header,
  isLoadingOptions,
  options,
  selectedOptions,
  selectAllState,
  selectAllDisplayedResultsState,
  emptyOptions,
  onTextChange,
  onDelayedTextChange,
  onSelectionUpdate,
  selectAllLabel,
  selectAllDisplayedResultsLabel,
  filterText = "",
  placeholderFilterText = "Search...",
  loadingOptions = DISPLAY_LOADING_OPTIONS,
  multiSelectClassName,
  isSingleSelect = false,
}): JSX.Element => {
  return (
    <Container header={<Header variant="h2">{header}</Header>}>
      <Box margin={{ top: "xs", bottom: "xs", left: "xxxs", right: "xxxs" }}>
        <SpaceBetween direction="vertical" size="s">
          <SearchBar
            filterText={filterText}
            onTextChange={onTextChange}
            onDelayedTextChange={onDelayedTextChange}
            placeholderText={placeholderFilterText}
          />
          {isSingleSelect ? (
            <SingleSelect
              isLoading={isLoadingOptions}
              options={options}
              selectedOptions={selectedOptions}
              loadingOptions={loadingOptions}
              emptyOptions={emptyOptions}
              onSelectionUpdate={onSelectionUpdate}
              singleSelectClassName={multiSelectClassName}
            />
          ) : (
            <MultiSelect
              isLoading={isLoadingOptions}
              options={options}
              selectedOptions={selectedOptions}
              selectAllState={selectAllState}
              selectAllDisplayedResultsState={selectAllDisplayedResultsState}
              loadingOptions={loadingOptions}
              emptyOptions={emptyOptions}
              selectAllLabel={selectAllLabel}
              selectAllDisplayedResultsLabel={selectAllDisplayedResultsLabel}
              onSelectionUpdate={onSelectionUpdate}
              multiSelectClassName={multiSelectClassName}
              filterText={filterText}
            />
          )}
        </SpaceBetween>
      </Box>
    </Container>
  );
};

export default FieldFilter;
