import { Button } from "@amzn/awsui-components-react-v3";
import React from "react";
import { FullscreenButtonProps } from "../../interfaces/FullscreenButtonProps";
import { ShrinkSvg } from "../../elements/ShrinkSVG/ShrinkSVG";

export const FullscreenButton: React.FC<FullscreenButtonProps> = ({
  handle,
  altText,
  ariaLabel,
  disabled,
  loading,
}): JSX.Element => {
  const expandIcon = handle.isFullscreen ? undefined : "expand";
  const shrinkIcon = handle.isFullscreen ? <ShrinkSvg /> : undefined;

  return (
    <Button
      data-shrink={!!shrinkIcon}
      data-expand={!!expandIcon}
      iconName={expandIcon}
      iconSvg={shrinkIcon}
      variant="icon"
      ariaLabel={ariaLabel}
      iconAlt={altText}
      disabled={disabled}
      loading={loading}
      onClick={handle.toggle}
    />
  );
};

export default FullscreenButton;
