import { pageHeaderUpdated } from "./slice";
import { useAppDispatch, useAppSelector } from "app/store";

export const usePageHeader = () => {
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.pageHeader);

  return {
    pageHeader: selector,
    updatePageHeader: (payload: any) => dispatch(pageHeaderUpdated(payload)),
  };
};
