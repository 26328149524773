export const i18nStrings = {
  overflowMenuTitleText: "Menu",
  overflowMenuTriggerText: "More",
};

export const profileActions = [
  {
    type: "menu-dropdown",
    id: "support-group",
    text: "Support",
    items: [
      {
        id: "documentation",
        text: "About Use Tax",
        href: "https://w.amazon.com/bin/view/TaxTech/GATES/IntercompanyServices/Projects/UseTax/",
        external: true,
        externalIconAriaLabel: " (opens in new tab)",
      },
      {
        id: "suggest-feature",
        text: "Suggest a feature",
        href: "https://sim.amazon.com/issues/create?template=d50dce8d-db6f-476b-b71d-9598f90266c2",
        external: true,
        externalIconAriaLabel: " (opens in new tab)",
      },
      {
        id: "report-bug",
        text: "Report a bug",
        href: "https://t.corp.amazon.com/create/templates/c8a08a9d-3d7e-43e2-add7-5977e96c5212",
        external: true,
        externalIconAriaLabel: " (opens in new tab)",
      },
      {
        id: "update-vertex-mapping",
        text: "Update Vertex Taxability Mapping",
        href: "https://issues.amazon.com/issues/create?template=03bcaba4-42b3-4735-8ee7-f2cb48cb616b",
        external: true,
        externalIconAriaLabel: " (opens in new tab)",
      },
    ],
  },
];
