import { CSSProperties } from "react";
import { DataGridHandle } from "react-data-grid";
import { SelectionType } from "../interfaces/Selection";
import { PopoverPlacement } from "../interfaces/CellPopoverProps";
import { RowHeightSizeType } from "../interfaces/DataGridProps";

const SCROLL_BAR = 20;
const EMPTY_HEIGHT = 500;
export const DEFAULT_HEADER_ROW_HEIGHT = 40;
export const DEFAULT_ROW_HEIGHTS = {
  xs: 20,
  s: 30,
  m: 40,
  l: 50,
  xl: 60,
};

export interface SelectedCell {
  rowIdx: number;
  colIdx: number;
}

export interface GetTableHeightClassNameProps {
  rowsCount: number;
  isFullscreen: boolean | undefined;
  isHeaderSticky: boolean | undefined;
  rowHeightSize: RowHeightSizeType;
}

export interface GetCSSPropertiesForCellPortalProps {
  portalTarget: HTMLElement;
  cellElement: HTMLElement | null | undefined;
  placement: PopoverPlacement;
  rowHeightSize: RowHeightSizeType;
}

export const getDynamicTableHeight = (
  rowsCount?: number,
  isLoading?: boolean,
  rowHeight: number = DEFAULT_ROW_HEIGHTS.m
): number => {
  if (!rowsCount || isLoading) {
    return EMPTY_HEIGHT;
  }
  const safeHeaderRowHeight = DEFAULT_HEADER_ROW_HEIGHT;
  return rowsCount * rowHeight + safeHeaderRowHeight + SCROLL_BAR;
};

export const getTableHeightClassName = (input: GetTableHeightClassNameProps): string => {
  const { rowsCount, isFullscreen, isHeaderSticky, rowHeightSize } = input;
  if (isFullscreen) {
    return "table-height-fullscreen";
  }
  const rowCountCeil = Math.ceil(rowsCount / 10);
  if (rowCountCeil === 0) {
    return "table-height-0";
  }
  return isHeaderSticky
    ? `table-height-${rowHeightSize}-${rowCountCeil}-vh`
    : `table-height-${rowHeightSize}-${rowCountCeil}`;
};

export const getOrDefault = <T>(value: T | null | undefined, defaultValue: T): T => {
  return value ?? defaultValue;
};

export const getPortalPositioningInGrid = (top: number, left: number): CSSProperties => {
  return {
    position: "absolute",
    top,
    left,
    zIndex: 1,
  };
};

export const getCSSPropertiesForCellPortal = (
  input: GetCSSPropertiesForCellPortalProps
): CSSProperties => {
  const { portalTarget, cellElement, placement, rowHeightSize } = input;
  let cellTop = 0;
  let cellLeft = 0;
  let cellWidth = 0;
  if (cellElement) {
    const cellRect = cellElement?.getBoundingClientRect();
    cellTop = getOrDefault(cellRect?.top, 0);
    cellLeft = getOrDefault(cellRect?.left, 0);
    cellWidth = getOrDefault(cellRect?.width, 0);
  }
  const portalTargetRect = portalTarget.getBoundingClientRect();
  const portalLeft = getOrDefault(portalTargetRect.left, 0);
  const portalTop = getOrDefault(portalTargetRect.top, 0);
  const top =
    placement === PopoverPlacement.below ? cellTop + DEFAULT_ROW_HEIGHTS[rowHeightSize] : cellTop;
  const left = placement === PopoverPlacement.right ? cellLeft + cellWidth : cellLeft;
  return getPortalPositioningInGrid(top - portalTop, left - portalLeft);
};

/**
 * Returns the cell element for given row and column.
 *
 * @param grid The data grid handle.
 * @param rowIdx The 1-based row index including header row.
 * @param colIdx The 1-based column index including selection column (if any).
 * @returns The corresponding cell element otherwise undefined.
 */
export const getCellElement = (
  grid: DataGridHandle | null,
  rowIdx: number,
  colIdx: number
): HTMLElement | null | undefined => {
  return grid?.element?.querySelector(
    `:scope > div[role="row"][aria-rowIndex="${rowIdx}"] > div[role='gridcell'][aria-colIndex="${colIdx}"]`
  );
};

/**
 * Returns the 0-based indexes for the selected cell (if any).
 *
 * @param grid The data grid handle.
 * @param selectionType Selection type for the grid to determine if selection column is present or not.
 * @returns The 0-based indexes for the selected cell (if any) otherwise undefined.
 */
export const getSelectedCell = (
  grid: DataGridHandle | null,
  selectionType?: SelectionType
): SelectedCell | undefined => {
  const cell = grid?.element?.querySelector(
    ":scope > div[role='row'] > div[role='gridcell'][aria-selected='true']"
  );

  const row = cell?.closest("div[role='row']");
  const colOffset = selectionType ? 2 : 1;

  const rowIdx = +getOrDefault(row?.getAttribute("aria-rowindex"), "0") - 2;
  const colIdx = +getOrDefault(cell?.getAttribute("aria-colindex"), "0") - colOffset;

  if (rowIdx >= 0 && colIdx >= 0) {
    return {
      rowIdx,
      colIdx,
    };
  }
  return undefined;
};
