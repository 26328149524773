import React, { Dispatch, SetStateAction } from "react";
/**
 * Hook for accessing local storage
 * @param storageKey: storageKey within which the data is stored
 * @param defaultValue : some default value if there is no storage
 * @returns the value & a handler to handle updates
 */
export const useLocalStorage = <T = any>(
  storageKey: string,
  defaultValue: T
): [T, Dispatch<SetStateAction<T>>] => {
  const [value, setValue] = React.useState(
    () => load(storageKey) ?? defaultValue
  );

  function handleValueChange(newValue: any) {
    setValue(newValue);
    save(storageKey, newValue);
  }
  return [value, handleValueChange];
};

const save = (storageKey: string, value: any) =>
  localStorage.setItem(storageKey, JSON.stringify(value));

const load = (storageKey: string) => {
  const value = localStorage.getItem(storageKey);
  try {
    return value && JSON.parse(value);
  } catch (e) {
    console.warn(
      `⚠️ The value for ${storageKey} stored in localStorage is incorrect. Try to remove the value ${storageKey} from localStorage and reload the page`
    );
    return undefined;
  }
};
