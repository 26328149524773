import React from "react";
import { IsItemDisabled, SelectionType } from "../interfaces/Selection";

export interface UseSelectionRequest<T> {
  items: readonly T[];
  selectedItems: Set<React.Key>;
  selectionType?: SelectionType;
  onSelectionChange?: (selectedKeys: Set<React.Key>) => void;
  rowKeyProvider?: (row: T) => React.Key;
  isItemDisabled?: IsItemDisabled<T>;
}

export interface UseSelectionResult {
  allItemsSelected: boolean;
  selectAllDisabled: boolean;
  selectionHandler?: (selectedKeys: Set<React.Key>) => void;
  onAllItemsSelectionChange?: (checked: boolean) => void;
}

const useSelection = <T extends unknown>({
  items,
  selectionType,
  selectedItems,
  onSelectionChange,
  rowKeyProvider,
  isItemDisabled,
}: UseSelectionRequest<T>): UseSelectionResult => {
  const eligibleItems = items.filter((item) => !isItemDisabled || !isItemDisabled(item));
  const selectAllDisabled = eligibleItems.length === 0;

  const allItemsSelected =
    !!rowKeyProvider &&
    !selectAllDisabled &&
    eligibleItems.every((item) => selectedItems.has(rowKeyProvider(item)));

  if (!selectionType || !onSelectionChange || !rowKeyProvider) {
    return {
      allItemsSelected,
      selectAllDisabled,
    };
  }

  const selectionHandler = (selectedKeys: Set<React.Key>): void => {
    if (selectionType === "single") {
      const checked = new Set<React.Key>();
      // Find newly checked option.
      selectedKeys.forEach((key) => {
        if (!selectedItems?.has(key)) {
          checked.add(key);
        }
      });
      // Pass only newly checked option or it was a deselection.
      onSelectionChange(checked);
    } else {
      onSelectionChange(selectedKeys);
    }
  };

  const onAllItemsSelectionChange = (checked: boolean): void => {
    const newSelectedItems = new Set(selectedItems);
    let newItems = items;

    if (isItemDisabled) {
      newItems = items.filter((item) => !isItemDisabled(item));
    }

    newItems.forEach((item) => {
      const rowKey = rowKeyProvider(item);
      if (checked) {
        newSelectedItems.add(rowKey);
      } else {
        newSelectedItems.delete(rowKey);
      }
    });

    selectionHandler(newSelectedItems);
  };

  return {
    allItemsSelected,
    selectAllDisabled,
    selectionHandler,
    onAllItemsSelectionChange,
  };
};

export default useSelection;
