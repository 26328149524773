import { useAppSelector } from "app/store";
import { ENABLED } from "./constants";

export const useVariation = (feature: string) => {
  const { isLoading, isError, features } = useAppSelector(
    (state) => state.evidently
  );

  if (!features.hasOwnProperty(feature)) {
    return {
      isLoading,
      isError: true,
      isEnabled: false,
      value: undefined,
    };
  }

  return {
    isLoading,
    isError,
    isEnabled: features[feature].variation === ENABLED,
    value: features[feature].value,
  };
};
