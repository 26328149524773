import { SelectProps } from "@amzn/awsui-components-react-v3";
import { FieldEditorProps } from "../../../data-grid/interfaces/FieldEditorProps";

export class SelectOptionDefinition {
  public option?: SelectProps.Option;

  constructor(option?: SelectProps.Option) {
    this.option = option;
  }

  public toString(): string {
    return this.option?.value || "";
  }
}

export type StatusType = "pending" | "loading" | "finished" | "error";

export interface SelectDropDownEditorProps extends FieldEditorProps<SelectOptionDefinition> {
  /**
   * Array of options to be displayed to the user as a drop-down list
   */
  dropDownOptions: SelectProps.Options;

  /**
   * Text to be displayed when there are no matches for the filtering (When filteringType = "auto")
   */
  noMatchText?: string;

  /**
   * Text to be displayed when in the loading state.
   */
  loadingText?: string;

  /**
   * Text to be displayed when there are no options to be displayed
   */
  noOptionsText?: string;

  /**
   * Text to be displayed when a data fetching error occurs. Need to provide recovery text
   */
  errorText?: string;

  /**
   * Text to be displayed for the recovery button. This text will be displayed next to error text.
   * Use the onLoadItems event to do the recovery action. (Eg: Retrying the request)
   */
  recoveryText?: string;

  /**
   * Specifies the hint text that's displayed in the field when no option has been selected.
   */
  placeholderText?: string;

  /**
   * Indicates the current status of loading options
   * `pending` - Indicates no data
   * `loading` - Indicates that data fetching is in progress.
   * `finished` - Indicates that data fetching is finished
   * `error` - Indicates that an error occurred during fetch. Should use `recoveryText` to enable the user to recover.
   */
  statusType: StatusType;

  /**
   * Called when the user clicks the `recoveryText`.
   */
  onRetry?: () => void;

  /**
   * A value in the drop-down that the user will choose in order to clear the already present value.
   */
  unSelectValue?: string;
}
