import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IInfoState } from "./interfaces";

const initialState: IInfoState = {
  tools: null,
  toolsOpen: false,
};

export const infoSlice = createSlice({
  name: "info",
  initialState,
  reducers: {
    infoUpdated: (state, action: PayloadAction<React.ReactNode>) => {
      state.tools = action.payload;
    },
    infoOpened: (state, action: PayloadAction<boolean>) => {
      state.toolsOpen = action.payload;
    },
  },
});

export const { infoUpdated, infoOpened } = infoSlice.actions;

export default infoSlice.reducer;
