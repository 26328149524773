import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDataOrchestrationYearMonthFiltersState } from "./interfaces";
import { getYearMonthForUseTaxCalculationFilter } from "utils";

const yearMonth = getYearMonthForUseTaxCalculationFilter();
const initialState: IDataOrchestrationYearMonthFiltersState = {
  year: yearMonth[0],
  month: yearMonth[1],
};

export const dataOrchestrationYearMonthFiltersSlice = createSlice({
  name: "dataOrchestrationYearMonthFilters",
  initialState,
  reducers: {
    updateYear: (state, action: PayloadAction<number>) => {
      state.year = action.payload;
    },
    updateMonth: (state, action: PayloadAction<string>) => {
      state.month = action.payload;
    },
  },
});

export const { updateYear, updateMonth } =
  dataOrchestrationYearMonthFiltersSlice.actions;

export default dataOrchestrationYearMonthFiltersSlice.reducer;
