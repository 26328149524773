import React from "react";

import Grid from "@amzn/awsui-components-react/polaris/grid";
import PropertyFilter, {
  PropertyFilterProps,
} from "@amzn/awsui-components-react/polaris/property-filter";
import Multiselect from "@amzn/awsui-components-react/polaris/multiselect";
import Select from "@amzn/awsui-components-react/polaris/select";

import { getFilterCounterText } from "components/client-side-paginated-table/utils";

import {
  MONTHS_FILTER,
  PROPERTY_FILTER_I18N_CONSTANTS,
  YEARS_FILTER,
  YEARS_MULTISELECT_FILTER,
  MONTHS_MULTISELECT_FILTER,
} from "./constants";
import {
  useCalculateTaxesTableFilters,
  useCalculateTaxesTableMultiSelectFilters,
} from "./hooks";
import { ICalculateTaxesTableFiltersProps } from "./interfaces";
import { EvidentlyContext } from "../../components/evidently-feature/evidentlyContext";
import { UTC_MULTISELECT_FEATURE } from "../../components/evidently-feature/constants";

const CalculateTaxesTableFilters: React.FC<
  ICalculateTaxesTableFiltersProps
> = ({
  filteringProperties,
  propertyFilterProps,
  filteredItemsCount,
  isMultiSelectEnabled,
  multiSelectSearchBarQuery,
  setMultiSelectSearchBarQuery,
  multiSelectSearchBarMatchingRecords,
}: ICalculateTaxesTableFiltersProps) => {
  const multiSelectVariation = EvidentlyContext.getFeatureVariation(
    UTC_MULTISELECT_FEATURE
  );

  const { calculateTaxesTableFilters, updateCalculateTaxesYearMonth } =
    useCalculateTaxesTableFilters();

  const {
    calculateTaxesTableMultiSelectFilters,
    updateCalculateTaxesYearsMonths,
  } = useCalculateTaxesTableMultiSelectFilters();

  if (
    isMultiSelectEnabled &&
    multiSelectSearchBarQuery?.tokens.length === 0 &&
    setMultiSelectSearchBarQuery
  ) {
    setMultiSelectSearchBarQuery(propertyFilterProps.query);
  }

  const PROPERTY_FILTER_PROPS: PropertyFilterProps = isMultiSelectEnabled
    ? filteringProperties &&
      setMultiSelectSearchBarQuery && {
        i18nStrings: PROPERTY_FILTER_I18N_CONSTANTS,
        filteringProperties: filteringProperties,
        query: propertyFilterProps.query,
        onChange: ({ detail }) => {
          setMultiSelectSearchBarQuery(detail);
          propertyFilterProps.onChange({ detail });
        },
        countText: multiSelectSearchBarMatchingRecords
          ? getFilterCounterText(multiSelectSearchBarMatchingRecords)
          : undefined,
      }
    : filteringProperties && {
        i18nStrings: PROPERTY_FILTER_I18N_CONSTANTS,
        ...propertyFilterProps,
        countText: getFilterCounterText(filteredItemsCount),
      };

  return multiSelectVariation === "Enabled" ? (
    <Grid
      gridDefinition={[
        {
          colspan: 5,
        },
        {
          colspan: 3,
        },
        {
          colspan: 3,
        },
      ]}
    >
      <div>
        <PropertyFilter {...PROPERTY_FILTER_PROPS} />
      </div>

      <div>
        <Multiselect
          data-testid="utc-table-year-filter"
          selectedOptions={calculateTaxesTableMultiSelectFilters.years.map(
            (year) => ({ label: year.toString(), value: year.toString() })
          )}
          onChange={({ detail }: any) => {
            updateCalculateTaxesYearsMonths({
              years: detail.selectedOptions.map((option: any) =>
                parseInt(option.value!)
              ),
              months: calculateTaxesTableMultiSelectFilters.months,
              pageNumber: 1,
              pageSize: 20,
            });
          }}
          options={YEARS_MULTISELECT_FILTER}
          placeholder={
            calculateTaxesTableMultiSelectFilters.years.length > 1
              ? "Multiple years selected"
              : "Select year(s)"
          }
          selectedAriaLabel="Selected"
          deselectAriaLabel={(e) => `Remove ${e.label}`}
          hideTokens={true}
        />
      </div>

      <div>
        <Multiselect
          data-testid="utc-table-month-filter"
          selectedOptions={calculateTaxesTableMultiSelectFilters.months.map(
            (month) => ({ label: month, value: month })
          )}
          onChange={({ detail }: any) => {
            updateCalculateTaxesYearsMonths({
              years: calculateTaxesTableMultiSelectFilters.years,
              months: detail.selectedOptions.map((option: any) => option.value),
              pageNumber: 1,
              pageSize: 20,
            });
          }}
          options={MONTHS_MULTISELECT_FILTER}
          placeholder={
            calculateTaxesTableMultiSelectFilters.months.length > 1
              ? "Multiple months selected"
              : "Select month(s)"
          }
          selectedAriaLabel="Selected"
          deselectAriaLabel={(e) => `Remove ${e.label}`}
          hideTokens={true}
        />
      </div>
    </Grid>
  ) : (
    <Grid
      gridDefinition={[
        {
          colspan: 5,
        },
        {
          colspan: 2,
        },
        {
          colspan: 3,
        },
      ]}
    >
      <div>
        {filteringProperties && (
          <PropertyFilter
            i18nStrings={PROPERTY_FILTER_I18N_CONSTANTS}
            {...propertyFilterProps}
            countText={getFilterCounterText(filteredItemsCount)}
          />
        )}
      </div>

      <div>
        <Select
          filteringType="auto"
          filteringPlaceholder="Year"
          options={YEARS_FILTER}
          selectedOption={{ value: calculateTaxesTableFilters.year.toString() }}
          onChange={({ detail }: any) => {
            updateCalculateTaxesYearMonth({
              year: parseInt(detail.selectedOption.value),
              month: calculateTaxesTableFilters.month,
            });
          }}
        />
      </div>

      <div>
        <Select
          filteringType="auto"
          filteringPlaceholder="Month"
          options={MONTHS_FILTER}
          selectedOption={{ value: calculateTaxesTableFilters.month }}
          onChange={({ detail }: any) => {
            updateCalculateTaxesYearMonth({
              year: calculateTaxesTableFilters.year,
              month: detail.selectedOption.value,
            });
          }}
        />
      </div>
    </Grid>
  );
};

export default CalculateTaxesTableFilters;
