import React, { ErrorInfo } from "react";
import { withTranslation } from "react-i18next";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import Box from "@amzn/awsui-components-react/polaris/box";
import ExpandableSection from "@amzn/awsui-components-react/polaris/expandable-section";
import { IErrorProps, IErrorState } from "./interfaces";
import { LOG } from "utils";

class ErrorBoundary extends React.Component<IErrorProps, IErrorState> {
  readonly exposeError: boolean = process.env.NODE_ENV === "development";
  public state: IErrorState = {
    error: undefined,
    errorInfo: undefined,
  };

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    });

    LOG.error(error, errorInfo.componentStack);
  }

  public render() {
    const { t } = this.props;

    if (this.state.error) {
      return (
        <Alert
          visible={true}
          type="error"
          header={t("components.error_boundary.header")}
          buttonText={t("components.error_boundary.buttonText")}
          onButtonClick={() => {
            window.location.reload();
          }}
        >
          {this.exposeError && (
            <Box>
              {this.state.error && (
                <Box variant="p">{this.state.error.toString()}</Box>
              )}

              {this.state.errorInfo && (
                <ExpandableSection
                  defaultExpanded
                  header={t("components.error_boundary.errorInfo")}
                  variant="footer"
                >
                  <Box variant="code">
                    {this.state.errorInfo.componentStack}
                  </Box>
                </ExpandableSection>
              )}
            </Box>
          )}
        </Alert>
      );
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
