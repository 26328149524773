import { localApi } from "services/local";
import { SettingsResponse } from "./interfaces";

const extendedApi = localApi.injectEndpoints({
  endpoints: (builder) => ({
    getSettings: builder.query<SettingsResponse, void>({
      query: () => "settings.json",
    }),
  }),
  overrideExisting: false,
});

export const { useGetSettingsQuery, usePrefetch } = extendedApi;
