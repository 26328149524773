import { Box, Container } from "@amzn/awsui-components-react-v3/polaris";
import React from "react";
import { Option } from "../../interfaces/Option";
import MultiSelectOption from "../MultiSelectOption/MultiSelectOption";

import styles from "../MultiSelect/multiSelectElement.module.scss";

interface SingleSelectProps {
  isLoading: boolean;
  loadingOptions: string | JSX.Element;
  emptyOptions: string | JSX.Element;
  options: Option[];
  selectedOptions: Option[];
  onSelectionUpdate: (isChecked: boolean, option: Option) => void;
  singleSelectClassName?: string;
}

const SingleSelect: React.FC<SingleSelectProps> = ({
  isLoading,
  loadingOptions,
  emptyOptions,
  options,
  selectedOptions,
  singleSelectClassName,
  onSelectionUpdate,
}): JSX.Element => {
  const singleSelectOptions = options.map((option: Option) => (
    <MultiSelectOption
      key={option.value}
      value={option.value}
      label={option.label}
      isChecked={selectedOptions.length === 1 && selectedOptions[0].value === option.value}
      onSelectionChange={onSelectionUpdate}
      isDisabled={selectedOptions.length === 1 && !(selectedOptions[0].value === option.value)}
    />
  ));

  return (
    <Container disableContentPaddings className={singleSelectClassName}>
      {isLoading && <Box margin="xxs">{loadingOptions}</Box>}
      {!isLoading && options.length === 0 && <Box margin="xxs">{emptyOptions}</Box>}
      {!isLoading && options.length > 0 && (
        <Box className={styles["box-container"]}>{singleSelectOptions}</Box>
      )}
    </Container>
  );
};

export default SingleSelect;
