import { BreadcrumbGroupProps } from "@amzn/awsui-components-react/polaris/breadcrumb-group";
import { useNavigate } from "react-router-dom";
import { breadcrumbsUpdated } from "./slice";
import { useAppDispatch, useAppSelector } from "app/store";

/**
 * Hook for handling breadcrumb redux state
 */
export const useBreadcrumbs = () => {
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.breadcrumbs);

  return {
    getBreadcrumbs: () => selector,
    updateBreadcrumbs: (payload: BreadcrumbGroupProps.Item[]) =>
      dispatch(breadcrumbsUpdated(payload)),
  };
};

/**
 * Hook for handling breadcrumb group events
 */
export const useOnClickHandler = () => {
  const navigate = useNavigate();

  return (event: CustomEvent<BreadcrumbGroupProps.ClickDetail>) => {
    if (!event.detail.external) {
      event.preventDefault();
      navigate(event.detail.href, { replace: true });
    }
  };
};
