import {
  applyMode,
  applyDensity,
  Mode,
  Density,
} from "@amzn/awsui-global-styles";
import "@amzn/awsui-global-styles/polaris.css";

// Set dark/light mode
applyMode(Mode.Light);

// Set compact/comfortable density
applyDensity(Density.Comfortable);
// document.body.classList.add("awsui-polaris-compact-mode");

// Activate visual refresh UI
// document.body.classList.add("awsui-visual-refresh");
