import React from "react";
import moment from "moment";

import { useAppSelector } from "app/store";
import ClickableText from "components/clickable-text";

import { ITimeProps } from "./interfaces";
import { useOnClickHandler } from "./hooks";
import { DEFAULT_FORMAT } from "./constants";

const Time: React.FC<ITimeProps> = (props: ITimeProps) => {
  const time = moment(props.timestamp);
  const formattedTime = time.format(props.format ?? DEFAULT_FORMAT);
  const relativeTime = time.fromNow();

  const { isRelativeTimeFormat } = useAppSelector((state) => state.time);
  const [label, setLabel] = React.useState<string>("");
  const [title, setTitle] = React.useState<string>("");
  const onClickHandler = useOnClickHandler();

  React.useEffect(() => {
    if (isRelativeTimeFormat) {
      setLabel(relativeTime);
      setTitle(formattedTime);
    } else {
      setLabel(formattedTime);
      setTitle(relativeTime);
    }
  }, [isRelativeTimeFormat, formattedTime, relativeTime]);

  return (
    <time onClick={onClickHandler} title={title}>
      <ClickableText text={label} variant={props.variant ?? "span"} />
    </time>
  );
};

export default Time;
