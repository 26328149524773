import { useAppDispatch, useAppSelector } from "app/store";
import { updateMonth, updateYear } from "./slice";

export const useApprovalsFilters = () => {
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.approvalsFilters);

  return {
    approvalsFilters: selector,
    updateYear: (payload: number) => dispatch(updateYear(payload)),
    updateMonth: (payload: string) => dispatch(updateMonth(payload)),
  };
};
